import React from 'react';
import GlImage from '../Image';
import { IFinalOverlaySignUpFields } from '@/types/contentful';
import { getImageData } from '~/utils/contentful';

import { SignUpCard } from '../SignUpCard';
import classNames from 'classnames';

export function FinalOverlaySignUp({
  backgroundImage,
  mobileBackgroundImage,
  signUpCard
}: IFinalOverlaySignUpFields) {
  return (
    <div className="block-container relative mt-container min-h-[680px]">
      <div className="absolute inset-0 z-0 inline-flex h-full w-full !max-w-none flex-col items-center justify-center">
        {backgroundImage && (
          <GlImage
            className={classNames('absolute left-0 top-0 z-0 w-full', {
              '!hidden tablet:!block': mobileBackgroundImage
            })}
            {...getImageData(backgroundImage)}
            loading="eager"
            layout="fill"
            objectFit="cover"
            width={undefined}
            height={undefined}
          />
        )}
        {mobileBackgroundImage && (
          <GlImage
            className="absolute left-0 top-0 z-0 !block w-full tablet:!hidden"
            {...getImageData(mobileBackgroundImage)}
            loading="eager"
            layout="fill"
            objectFit="cover"
            width={undefined}
            height={undefined}
          />
        )}
        {signUpCard && <SignUpCard {...signUpCard.fields} variant={'medium'} />}
      </div>
    </div>
  );
}
